import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid"
import { Fade } from "react-reveal"
import Quotation from "../core/Quotation"
import WavesImg from "../../resources/images/waves.svg"
import Img from "gatsby-image"
import DecorationTitle from "./DecorationTitle"
import IntroBlockBackgroundImg from "../../resources/images/voyage/introblock-background.jpg"
import MobileIntroBlockBackgroundImg from "../../resources/images/voyage/introblock-background-mobile.jpg"
import BottomBackgroundImg from "../../resources/images/voyage/background-banner-bottom.jpg"
import MobileBottomBackgroundImg from "../../resources/images/voyage/bacground-banner-bottom-mobile.jpg"

const DarkBlockContainer = styled.div`
    width: 100%;
    background: rgb(2, 9, 19);
    position: relative;
    overflow: hidden;
    height: auto;
`

const ContentBlockContainer = styled.div`
    width: 100vw;
    position: relative;
`

const VoyageContainer = styled(Container)`
     padding-left: 0;
     padding-right: 0;

`

const IntroBlockBackground = styled.div`
    width: 100vw;
    height: 859px;
    background: url(${MobileIntroBlockBackgroundImg}) no-repeat;
    z-index: 0;
    background-size: cover;
    margin-bottom: -500px;
    margin-top: -66px;
    @media(min-width: 768px){
        height: 729px;
        margin-top: -300px;
        background: url(${IntroBlockBackgroundImg}) no-repeat;
        background-position: 50%;
        background-size: cover;
    }
    @media(min-width: 1500px){
      padding-bottom: 300px;
      margin-top: -600px;
    }
`

const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
`

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
    position: relative;
    display: ${props => props.flex ? "flex" : ""};
`

const Waves = styled.img`
    left: 115.5px;
    position: absolute;
    bottom: -58.5px;
    @media(max-width: 1199px) {
        bottom: -58.5px;
    }
    @media(max-width: 768px) {
        display: none;
    }
`

const Description = styled.div`
    color: #9CA8B5;
    font-size: 15px;
    line-height: 21px;
    ${fonts.gilroyRegular};
    position: relative;
    padding: 40px 34px 0;
    z-index:1;
    @media(min-width: 768px) {
        max-width: 360px;
        padding: 0;
    }
`

const DesktopOnly = styled.div`
    display: none;
    @media(min-width: 768px) {
      display: flex;
    }
`

const MobileOnly = styled.div`
    display: flex;
 
    @media(min-width: 768px) {
      display: none;
    }
`

const RightQuotationBlock = styled.div`
    position: relative;
    margin-left: -140px;
    margin-top: 35px;
    margin-bottom: 20px;
    max-width: 250px;
    @media(min-width: 768px){
      margin-top: 20px;
      margin-bottom: 20px;
      max-width: 550px;
      margin-left: -210px;
    }
    @media(min-width: 992px){
      margin-top: 40px;
      margin-bottom: 40px;  
    }
`

const LeftQuotationBlock = styled.div`
    position: relative;
    margin-top: 28px;
    width: 268px;
    right: 20px;
    z-index: 1;
    @media(min-width: 768px){
      margin-top: 20px;
      margin-bottom: 20px;
      min-width: 450px;
      max-width: 551px;
      margin-left: 20px;
    }
    @media(min-width: 992px){
      margin-top: 40px;
      margin-bottom: 40px;
      min-width: 551px;
    }
`

const FirstDescriptionBox = styled.div`
    padding-left: 56px;
    padding-top: 40px;
    @media(min-width: 992px){
        padding-top: 100px;
        padding-left: 113px;
    }
`

const SecondDescriptionBox = styled.div`
  float: right;
  padding-top: 0;
  @media(min-width: 768px){
    padding-top: 150px;
    padding-right: 20px;
  }
  @media(min-width: 992px) {
    padding-top: 150px;
    padding-right: 0;
  }
`

const ThirdDescriptionBox = styled.div`
  @media(min-width: 768px){
      padding-top: 35px;
      padding-left: 57px;
  }
  @media(min-width: 992px) {
      padding-top: 107px;
      padding-left: 114px;
  }
`

const FourthDescriptionBox = styled.div`
    padding-left: 56px;
    padding-top: 40px;
    @media(min-width: 992px){
        padding-top: 174px;
        padding-left: 95px;
    }
`

const IntroImgLeftContainer = styled.div`
    width: 100%;
    height: auto;
`

const IntroImgLeft = styled(Img)`
    height: 100%;
    margin-top: 33px;
    width: 244px;
    @media(min-width: 768px){
        margin-top: 0;
        width: 100%;
        max-width: 554px;
        padding-bottom: 0;
        margin-bottom: 0;
    }
`

const IntroImgRightSecondContainer = styled.div`
    width: 100%;
    height: auto;
`

const IntroImgRightSecond = styled(Img)`
    height: 171px;
    margin-top: 108px;
    width: 212px;
    margin-left: -34px;
    @media(min-width: 768px){
        width: 244px;
        height: 100%;
        margin-top: 50px;
        float: right;
    }
        @media(min-width: 992px){
        width: 363px;
        margin-top: 108px;
    }
`

const IntroImgRightContainer = styled.div`
    width: 100%;
    height: auto;
    text-align: center;
    position: relative;
`

const IntroImgRight = styled(Img)`
    height: 100%;
    width: 259px;
    z-index: 1;
    margin: 75px auto 15px auto;
    @media(min-width: 768px){
        width: 363px;
        float: right;
    }
`

const IntroImgLeftSecondContainer = styled.div`
    width: 100%;
    height: auto;
`

const IntroImgLeftSecond = styled(Img)`
    height: 100%;
    width: 348px;
    margin: 0 auto 21px auto;
    @media(min-width: 768px){
        width: 100%;
        max-width: 553px;
    }
`

const BottomText = styled.div`
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.3px;
    padding: 0 25px 60px 43px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    margin-top: 140px;
    ${fonts.swissBlackExtended};
    @media(min-width: 768px){
        line-height: 34px;
        font-size: 28px;
        letter-spacing: 1.8px;
        margin-top: 0;
    }
`

const BottomTextBox = styled.div`
    @media(min-width: 768px){
        min-height: 292px;
        margin-left: auto;
        margin-right: auto;
        max-width: 847px;
    }
`

const BottomBackgroundMobile = styled.div`
    background: url(${MobileBottomBackgroundImg}) no-repeat;
    height: 859px;
    width: 100vw;
    z-index: 0;
    margin-top: -959px;
    padding-bottom: 100px;
    background-position: 50%;
    background-size: cover;
    @media(min-width: 768px){
        display: none;
    }
`
const BottomBackground = styled.div`
    display: none;
    @media(min-width: 768px){
        display: block;
        background: url(${BottomBackgroundImg}) no-repeat;
        background-size: cover;
        height: 621px;
        margin-top: -621px;
        padding-bottom: 176px;
    }
`

const OurVoyageIntro = ({ introImage1, introImage2, introImage3, introImage4 }) => {
  return (
    <DarkBlockContainer>
      <ContentBlockContainer>
        <VoyageContainer>
          <MobileOnly>
            <RowStyled>
              <ColStyled md={12}>
                <Description>
                  The Lost at Sea story starts with a salute to life’s adventurers. The ones who ask themselves...
                </Description>
              </ColStyled>
            </RowStyled>
          </MobileOnly>
          <RowStyled>
            <ColStyled xs={6} sm={6}>
              <Fade duration={1000} delay={250}>
                <IntroImgLeftContainer>
                  <IntroImgLeft fluid={introImage1.childImageSharp.fluid} alt={"Intro image"}/>
                </IntroImgLeftContainer>
                <Waves src={WavesImg}/>
              </Fade>
            </ColStyled>
            <ColStyled xs={6} sm={6}>
              <FirstDescriptionBox>
                <DesktopOnly>
                  <Fade duration={750} delay={750}>
                    <Description>The Lost at Sea story starts with a salute to life’s adventurers. The ones who ask themselves...</Description>
                  </Fade>
                </DesktopOnly>
                <RightQuotationBlock>
                  <Fade right duration={1000} delay={0}>
                    <Quotation text={"“I wonder what’s round that corner, over that horizon, beyond that ocean?”"}/>
                  </Fade>
                </RightQuotationBlock>
                <DesktopOnly>
                  <Fade duration={750} delay={750}>
                    <Description>
                      The ones who get off their backside and set sail to find out. The voyagers, the pioneers, the ones
                      who know it can’t be done but do it anyway. Some don’t make it back, but those who do change the
                      world.
                    </Description>
                  </Fade>
                </DesktopOnly>
              </FirstDescriptionBox>
            </ColStyled>
          </RowStyled>
          <MobileOnly>
            <RowStyled>
              <ColStyled sm={12}>
                <Description>
                  The ones who get off their backside and set sail to find out. The voyagers, the pioneers,
                  the ones who know it can’t be done but do it anyway. Some don’t make it back, but those who do change
                  the world.
                </Description>
              </ColStyled>
            </RowStyled>
          </MobileOnly>
          <RowStyled>
            <ColStyled md={6} xsOrder={2} smOrder={2} mdOrder={1}>
              <SecondDescriptionBox>
                <Fade duration={750} delay={750}>
                  <Description>
                    Because unless we risk getting lost, we risk achieving nothing. Without “I wonder…” there’d have
                    been no
                    industrial revolution and no digital revolution. No Shakespeare, Newton or Darwin. No Bowie, Hawking
                    or
                    Berners-Lee. Heroes, one and all. So let’s raise a glass those who voyage into the unknown. They’ve
                    made
                    our world a better place.
                  </Description>
                </Fade>
              </SecondDescriptionBox>
            </ColStyled>
            <ColStyled md={6} xsOrder={1} smOrder={1} mdOrder={2}>
              <IntroImgRightContainer>
                <Fade duration={1000} delay={1000}>
                  <IntroImgRight fluid={introImage2.childImageSharp.fluid} alt={"Intro image"}/>
                </Fade>
              </IntroImgRightContainer>
            </ColStyled>
          </RowStyled>
        </VoyageContainer>
        <IntroBlockBackground/>
        <RowStyled>
          <ColStyled>
            <DecorationTitle/>
          </ColStyled>
        </RowStyled>
        <VoyageContainer>
          <RowStyled>
            <ColStyled md={6} xsOrder={1} smOrder={1} mdOrder={1}>
              <Fade duration={1000} delay={250}>
                <IntroImgLeftSecondContainer>
                  <IntroImgLeftSecond fluid={introImage3.childImageSharp.fluid} alt={"Intro image"}/>
                </IntroImgLeftSecondContainer>
              </Fade>
            </ColStyled>
            <ColStyled md={6} xsOrder={2} smOrder={2} mdOrder={2}>
              <ThirdDescriptionBox>
                <Fade duration={1000} delay={250}>
                  <Description>
                    It’s that attitude that led brothers Mike and Oli to start Lost at Sea. Their love affair with the
                    British coast was inspired by their grandfather’s tales of life as a seaside evacuee and Britain’s
                    proud seafaring heritage. They realised the same spirit of adventure and desire to explore shown by
                    generations of sailors was the driving force behind so many remarkable achievements in every area of
                    human endeavour.
                  </Description>
                </Fade>
              </ThirdDescriptionBox>
            </ColStyled>
          </RowStyled>
          <MobileOnly>
            <RowStyled>
              <ColStyled md={12}>
                <Description>
                  They set about creating a range of British heritage-inspired authentic beers characterised by
                  craftsmanship and attention to detail and inspired by their passion for the British coast. For Mike
                  and Oli, Lost at Sea isn’t so much a brand as a way of thinking, one that says...
                </Description>
              </ColStyled>
            </RowStyled>
          </MobileOnly>
          <RowStyled>
            <ColStyled xs={6} sm={6}>
              <FourthDescriptionBox>
                <DesktopOnly>
                  <Fade duration={1000} delay={250}>
                    <Description>They set about creating a range of British heritage-inspired authentic beers
                      characterised by craftsmanship and attention to detail and inspired by their passion for the
                      British
                      coast. For Mike and Oli, Lost at Sea isn’t so much a brand as a way of thinking, one that says...
                    </Description>
                  </Fade>
                </DesktopOnly>
                <LeftQuotationBlock>
                  <Fade left duration={1000} delay={0}>
                    <Quotation text={"“Let’s give it a go, so what if we get lost?”"}/>
                  </Fade>
                </LeftQuotationBlock>
                <DesktopOnly>
                  <Fade duration={1000} delay={0}>
                    <Description>
                      That’s why everything under the Lost At Sea flag has the same spirit of bravery, belief and a
                      burning desire to go further.
                    </Description>
                  </Fade>
                </DesktopOnly>
              </FourthDescriptionBox>
            </ColStyled>
            <ColStyled xs={6} sm={6}>
              <IntroImgRightSecondContainer>
                <Fade duration={1000} delay={1000}>
                  <IntroImgRightSecond fluid={introImage4.childImageSharp.fluid} alt={"Intro image"}/>
                </Fade>
              </IntroImgRightSecondContainer>
            </ColStyled>
          </RowStyled>
          <MobileOnly>
            <RowStyled>
              <ColStyled sm={12}>
                <Description>That’s why everything under the Lost At Sea flag has the same spirit of bravery, belief and
                  a burning desire to go further.
                </Description>
              </ColStyled>
            </RowStyled>
          </MobileOnly>
        </VoyageContainer>
        <BottomBackground/>
        <RowStyled>
          <BottomTextBox>
            <Fade duration={1000} delay={250}>
              <BottomText>
                So that’s Lost at Sea. A simple idea inspired by the UK’s nautical heritage, the rugged splendour of
                the
                British coast and the spirit of innovation and discovery that led to some of life’s most remarkable
                achievements. Long may it continue.
              </BottomText>
            </Fade>
          </BottomTextBox>
        </RowStyled>
        <BottomBackgroundMobile/>
      </ContentBlockContainer>
    </DarkBlockContainer>
  )
}

export default OurVoyageIntro

