import React from "react";
import styled from "styled-components";
import { Fade } from "react-reveal";
import BackgroundImage from "gatsby-background-image";
import Line from "../core/Line";
import WhiteTitle from "../core/WhiteTitle";
import ShortOverlayBottom from "../core/ShortOverlayBottom";

const Background = styled.div`
    height: 400px;
    background: #020913;
    z-index: -11;
    @media(min-width: 768px) {
        height: 500px;
    }
`

const BackgroundStyled = styled.div`
    position: absolute;
    height: 500px;
    margin-top: -138px;
    text-align: center;
    background: #020913;
    @media(max-width: 768px) {
        height: 420px;
    }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100vw;
  height: 100%;
  background-size: cover;
  max-height: 500px;
`;

const TitleContainer = styled.div`
    max-height: 420px;
    padding-top: 10px;
    @media(min-width: 768px) {
        padding-top: 45px;
    }
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 0.3);
  position: absolute;
  top: 0;
  left: 0;
`;

const Hero = ({ hero }) => {

  return (
    <Background>
      <BackgroundStyled>
        <BackgroundImageStyled fluid={hero.childImageSharp.fluid} critical={true} durationFadeIn={200}/>
        <Overlay/>
        <ShortOverlayBottom/>
      </BackgroundStyled>
      <TitleContainer>
        <Fade duration={750} delay={500}><WhiteTitle title={"Our Voyage"}/></Fade>
        <Fade bottom duration={750} delay={500}>
          <Line/>
        </Fade>
      </TitleContainer>
    </Background>
  )
}

export default Hero