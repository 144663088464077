import React from "react"
import { Zoom } from "react-reveal"
import DecorationTitleImg1 from "../../resources/images/voyage/decoration-title-1.svg"
import DecorationTitleImg2 from "../../resources/images/voyage/decoration-title-2.svg"
import DecorationTitleImg3 from "../../resources/images/voyage/decoration-title-3.svg"
import DecorationTitleImg4 from "../../resources/images/voyage/decoration-title-4.svg"
import DecorationTitleImg5 from "../../resources/images/voyage/decoration-title-5.svg"
import DecorationTitleImg6 from "../../resources/images/voyage/decoration-title-6.svg"
import DecorationTitleImg7 from "../../resources/images/voyage/decoration-title-7.svg"
import DecorationTitleImg8 from "../../resources/images/voyage/decoration-title-8.svg"
import styled from "styled-components"

const DecorationBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    padding-top: 207px;
    padding-bottom: 210px;
    @media(max-width: 768px) {
        margin-top: -150px;
        padding: 0;
        margin-bottom: 0;
    }
`

const TitleLetter = styled.img`
    height: ${props => props.small ? "26px" : "113px"};
    @media(max-width: 1162px){
        height: ${props => props.small ? "15px" : "63px"};
    }
    @media(max-width: 830px){
        height: ${props => props.small ? "7px" : "40px"};
    }
    @media(max-width: 420px){
        height: ${props => props.small ? "5px" : "33px"};
    }
`

const DecorationTitle = () => {
  return (
    <DecorationBox>
      <Zoom cascade duration={3000}>
        <TitleLetter src={DecorationTitleImg1}/>
        <TitleLetter src={DecorationTitleImg2}/>
        <TitleLetter src={DecorationTitleImg3}/>
        <TitleLetter src={DecorationTitleImg4}/>
        <TitleLetter src={DecorationTitleImg5}/>
        <TitleLetter src={DecorationTitleImg6}/>
        <TitleLetter src={DecorationTitleImg7}/>
        <TitleLetter src={DecorationTitleImg8}/>
      </Zoom>
    </DecorationBox>
  )
}

export default DecorationTitle
