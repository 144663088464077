import React, { Component } from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import Hero from "../components/voyage/Hero.js"
import OurVoyageIntro from "../components/voyage/OurVoyageIntro"
import get from 'lodash/get';
import PrivateRoute from "../router/PrivateRoute"

class OurVoyagePage extends Component {
  render() {
    const path = this.props.location.pathname;
    const data = get(this, 'props.data');

    const {
      page,
      hero,
      introImage1,
      introImage2,
      introImage3,
      introImage4
    } = data;

    return (
      <Layout>
        <SEO title={page.metaTitle}
             description={page.metaDescription}
             fullTitle={false}
             path={path}/>
        <Hero hero={hero}/>
        <OurVoyageIntro introImage1={introImage1} introImage2={introImage2} introImage3={introImage3} introImage4={introImage4}/>
      </Layout>
    )
  }
}

function voyage(props) {
  return <PrivateRoute component={OurVoyagePage} {...props}/>
}

export default voyage;

export const pageQuery = graphql`
  query OurVoyagePageQuery {
    page: contentfulLandingPage(slug: { eq: "voyage" }) {
      id
      metaTitle
      metaDescription
    }
    hero: file(relativePath: { eq: "voyage/hero.jpg" }) {
        childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
    introImage1: file(relativePath: { eq: "voyage/intro-img-opt-01.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1108) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    introImage2: file(relativePath: { eq: "voyage/intro-img-2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 726) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    introImage3: file(relativePath: { eq: "voyage/intro-img-3.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1106) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
      introImage4: file(relativePath: { eq: "voyage/intro-img-4.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 726) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
