import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts'

const StyledQuotation = styled.h1`
    text-transform: uppercase;
    color: white;
    line-height: 24px;
    font-size: 20px;
    letter-spacing: 1.3px;
    ${fonts.swissBlackExtended}
    @media (min-width: 991px) {
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.8px;
    }  
`;


const Quotation = (props) => {
  const { text } = props;
  return (<StyledQuotation>{text}</StyledQuotation>);
}

export default Quotation;